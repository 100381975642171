// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "24.4.0",
  "buildNumber": "19518",
  "gitSha": "a62b36c9ad757730b096d673e4e6f00dd199cc81",
  "fullVersion": "24.4.0-19518",
  "formattedVersion": "24.4.0 (19518-a62b36c9ad757730b096d673e4e6f00dd199cc81)",
  "copyrightYear": "2024"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/24.4.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/24.4.0/${stripLeadingPrefix(suffix, '#')}`
}
    
